export default{/**
   * 输入最长限制 <= max 默认 50
   */inputMaxLimit:function(){let max=arguments.length>0&&arguments[0]!==undefined?arguments[0]:50;return{validator:(rule,value)=>{if((value===null||value===void 0?void 0:value.length)>max){return Promise.reject(new Error("\u8BF7\u52FF\u8D85\u8FC7".concat(max,"\u4E2A\u5B57\u7B26")));}return Promise.resolve();}};},/**
   *
   * @param phoneNumber 手机号码
   * @returns 校验手机号码
   */validatePhoneNumber:()=>({validator:(rule,value)=>{const phoneNumberRegex=/^1[3456789]\d{9}$/;if(phoneNumberRegex.test(value)){return Promise.resolve();}return Promise.reject(new Error('请输入正确的手机号码'));}})};/**
 * 校验空
 * @param rule 
 * @param value 
 * @returns 
 */export const validateEmpty=(rule,value)=>{if(value&&value.trim()){return Promise.resolve();}return Promise.reject();};