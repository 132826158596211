import{toChineseNumber}from"@/utils/str";export const Dict_SubjectClassify='subjectClassify';// 科目属性归类
export const Dict_FieldClassifi='fieldClassify';// 字段归类
export const Dict_EngFeature='ProjectCharacter';// 工程特征
export const Dict_ProjectInfo="projectinformation";// 项目信息
export const Dict_engineeringInfo="gcxx";// 工程信息
export const Dict_SubjectAttributes="subjectAttributes";// 科目属性
export const Dict_ProjectType='projectFieldType';// 项目类型，数字，文本等
export const Dict_Enum='enum';// 项目类型，数字，文本等
export const Dict_Caliber='caliber';// 计算口径
export const Dict_ProjectPhase='ProjectPhase';// 项目阶段
export const Dict_EngUnit='engUnit';//
export const Dict_EngUnit_Label='工程单位';//
export const Dict_Enum_Label='枚举';//
export const Dict_Required="required";// 是否必填
export const Dict_EngineeringClassification="EngineeringType";// 工程分类
export const Dict_EngineeringClassificationUnit="projectCategoryUnit";// 工程分类
export const Dict_EngineeringSpecialty="EngineeringSpecialty";// 工程专业
export const Dict_DatasourceType='datasourceType';// 数据源类型
export const Dict_ProjectFieldType='projectFieldType';// 字段类型
// 项目信息-二级字典
export const Dict_FieldClassifi_BaseInfo="baseInfo";// 字段归类，基本信息
export const Dict_FieldClassifi_ContractInfo="contractInfo";// 字段归类，合同信息
export const Dict_FieldClassifi_Participatingunits="participatingunits";// 字段归类，参与单位
export const Dict_FieldClassifi_CostInformation="CostInformation";// 字段归类，造价信息
// 科目属性-二级字典
export const Dict_SubjectClassify_Economic="economicSubject";// 科目属性归类，经济指标科目
// 项目信息
export const Dict_ProjectInfo_ProjectName="projectname";// 项目名称
export const Dict_ProjectInfo_Projectlocation="Projectlocation";// 项目地点
export const Dict_ProjectInfo_Engineering_Type="Engineering_Type";// 工程分类 项目字段
export const Dict_ProjectInfo_Businessformat="Businessformat";// 工程业态
export const Dict_ProjectInfo_Projectnumber="Projectnumber";// 项目编号
export const Dict_ProjectInfo_ProjectPhase="ProjectPhase1";// 项目阶段
export const Dict_ProjectInfo_Projectscale="Projectscale";// 项目规模
// export const Dict_ProjectInfo_Projectscale_Unit = "Project_Scale_Unit" // 项目规模单位
export const Dict_ProjectInfo_Projectscale_Unit="ProjectScaleUnit";// 项目规模单位
export const Dict_ProjectInfo_Projectnature="projectnature";// 项目性质
export const Dict_ProjectInfo_CommencementDate="CommencementDate";// 开工日期
export const Dict_ProjectInfo_CompletionDate="CompletionDate";// 竣工日期
export const Dict_ProjectInfo_FileType="filetype";// 文件类型
// 基本类型
export const Dict_ProjectType_Number="Number";export const Dict_ProjectType_Text="Text";export const Dict_ProjectType_Date="Datetime";export const Dict_ProjectType_Select="Select";export const Dict_ProjectType_MultiSelect="Select_Multi";export const Dict_ProjectType_Location="location1";// 工程信息
export const Dict_EngineeringInfo_EngName="costName";// 工程名称
export const Dict_EngineeringInfo_EngCode="costCode";// 工程编码
export const Dict_EngineeringInfo_CollectTemplate="collTmpl";// 采集模板
export const Dict_EngineeringInfo_ProjectPhase="projectPhase";// 采集模板
// 计算口径 (工程特征）
// 建筑面积 1-0011
export const Dict_EngFeature_BuildingArea="1-0011";// 地上建筑面积 1-002
export const Dict_EngFeature_BuildingArea_Ground="1-002";// 地下建筑面积 1-003
export const Dict_EngFeature_BuildingArea_Underground="1-003";// 非人防车库面积 1-004
export const Dict_EngFeature_BuildingArea_NonPreventive="1-004";// 人防车库面积 1-005
export const Dict_EngFeature_BuildingArea_Preventive="1-005";// 景观面积 1-108
export const Dict_EngFeature_LandArea="1-108";// 10 ：含量-分子（工程量）， 11 ：含量-分子（工程造价）， 12 ：含量-分子（计算口径），
// 20 ：含量-分母（工程量）， 21 ：含量-分母（工程造价）， 22 ：含量-分母（计算口径）
export const fenziTypeArr=[10,11,12];export const fenmuTypeArr=[20,21,22];export const gclArr=[10,20];export const gczjArr=[11,21];export const jskjArr=[12,22];export const OTHER_FEE="OTHER_FEE";// 其他费用
export const STAT_FEE="STAT_FEE";// 规费
export const TAX_FEE="TAX_FEE";// 税金
// 'slickgrid-edit-disabled'
export const SlickgridEditDisabledClassName="slickgrid-edit-disabled";export const dxColorClassName='dx_bg_olor';export const dwColorClassName='dw_bg_color';export const fbfxColorClassName='fbfx_bg_color';export const qdColorClassName='qd_bg_color';export const LEVEL_LABEL=Object.fromEntries(Array.from({length:10},(_,i)=>[i+1,"\u5C55\u5F00".concat(toChineseNumber(i+1),"\u7EA7")]));export const LOCATION_OPTIONS=['上海','浙江','江苏','四川'];// 特殊code，一般不重要，不显示，或者放在最后面
export const ZBKM_HIDDEN_CODE_OPTIONS=['NOT_MATCH','OTHER_FEE','STAT_FEE','TAX_FEE'];export const ZBKM_CODE_SYSTEM_OPTIONS=['ROOT','NOT_MATCH','OTHER_FEE','STAT_FEE','TAX_FEE'];export const RN_CODE='ROOT';// 默认企业epid
export const DEFAULT_EPID='2285';// 重复码
export const COST_REDO_CODE=82011;export const PROJECT_REDO_CODES=[82010,82012];// 是否是快速上传标记
export const Uploaded='uploaded';export const COST_HEIGHT={header:53,footer:40};