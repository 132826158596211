import _objectSpread from"/Users/mac/code/luban/test/cost-cloud-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";import axios from'axios';import{message}from'antd';import{isNumber}from'lodash-es';import qs from'qs';import{getStorage}from'./tools';import{loginAgainConfirm}from'./help';// http://192.168.13.140:8182/gateway
// 打包时根据自定义的环境变量 NODE_STAGE 选择不同的api
const ConfigApi={development:'https://zjy-pre.lubansoft.com/gateway',// development: 'http://192.168.13.141:83/gateway',
qa:'http://192.168.13.141:83/gateway',// 测试
// development: 'http://192.168.13.147:8185/gateway',
// qa: 'http://192.168.13.147:8185/gateway', // 测试
// development: 'http://222.71.50.180:8185/gateway',
// qa: 'http://222.71.50.180:8185/gateway', // 测试
// 222.71.50.180:8185/gateway
// development: 'https://zjy.lubansoft.com/gateway',
// development: 'http://192.168.13.141:83/gateway-147',
// qa: 'http://192.168.13.141:83/gateway-147',
pre:window.BASE_URL||'https://zjy-pre.lubansoft.com/gateway',// 预发布环境
production:window.BASE_URL||'https://zjy.lubansoft.com/gateway'// 线上
};// center api配置
const ConfigCenterApi={// development: 'https://zjy.lubansoft.com/gateway',
development:'http://192.168.13.140:8182/gateway',qa:'http://192.168.13.140:8182/gateway',// 测试
pre:window.BASE_URL||'https://zjy-pre.lubansoft.com/gateway',// 预发布环境
production:window.BASE_URL||'https://zjy.lubansoft.com/gateway'// 线上
};// 人材机
const ConfigRcjApi={// development: 'https://zjy.lubansoft.com/gateway',
development:'http://192.168.13.150:8283',qa:'http://192.168.13.150:8283',// 测试
pre:window.BASE_URL||'https://zjy-pre.lubansoft.com/gateway',// 预发布环境
production:window.BASE_URL||'https://zjy.lubansoft.com/gateway'// 线上
};// 临时测试使用
const CostomConfigApi={development:'http://192.168.13.141:8489/cc-dictionary',qa:'http://192.168.13.141:83/gateway',// 测试
pre:window.BASE_URL||'https://zjy-pre.lubansoft.com/gateway',// 预发布环境
production:window.BASE_URL||'https://zjy.lubansoft.com/gateway'// 线上
};/**
 * 重组URL
 * @param path
 * @returns {string}
 */export function getUrl(path){let platform=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'api';let baseUrl;if(platform==='api'){baseUrl=ConfigApi[process.env.NODE_STAGE||'development'];}if(platform==='center'){baseUrl=ConfigCenterApi[process.env.NODE_STAGE||'development'];}if(platform==='custom'){baseUrl=ConfigApi[process.env.NODE_STAGE||'development'];}if(platform==='rcj'){baseUrl=ConfigRcjApi[process.env.NODE_STAGE||'development'];}if(platform==='none'){return path;}return"".concat(baseUrl).concat(path);}// 指定 axios 请求主体类型
axios.defaults.headers['Content-Type']='application/json;charset=utf-8';// 是否在跨域请求中发送凭据，如cookies或授权头
axios.defaults.withCredentials=true;// 添加请求拦截器
axios.interceptors.request.use(config=>{var _config$url;// 获取用户token，用于校验
if(!((_config$url=config.url)!==null&&_config$url!==void 0&&_config$url.includes('/login'))){const token=getStorage('token');if(token){config.headers['access-token']=token;}}return config;},error=>Promise.reject(error));// 添加响应拦截器，拦截登录过期或者没有权限
axios.interceptors.response.use(response=>{var _response$data;if(!(response!==null&&response!==void 0&&(_response$data=response.data)!==null&&_response$data!==void 0&&_response$data.code)){return response;}const{code,msg}=response.data;// 登录已过期，token失效
if(code===401||code===1002||code===1007||code===1021||code===1013){loginAgainConfirm();// 重定向登录界面
return Promise.reject(response);}// 错误码
const errCodes=[500,1007,40000,-119,0,50000,1006,1035,1037,1021,400,-102,1003,1014,1016,-1];// const warningCodes = [80003]
// 重置builder的错误信息
if(code==1015){response.data.msg='当前用户没有可用授权~';response.data.message='当前用户没有可用授权~';}if(isNumber(code)&&errCodes.includes(code)){message.error(response.data.msg||response.data.message);return Promise.reject(response);}return response;},error=>{console.log('error :>> ',error);try{var _response$data2,_response$data3;const{response}=error;const msgInfo=(response===null||response===void 0?void 0:(_response$data2=response.data)===null||_response$data2===void 0?void 0:_response$data2.msg)||(response===null||response===void 0?void 0:(_response$data3=response.data)===null||_response$data3===void 0?void 0:_response$data3.message)||'服务错误';// 处理不同网络状态码下的错误
if((response===null||response===void 0?void 0:response.status)===401){// message.error('没有当前功能权限，请联系管理员')
loginAgainConfirm();// 重定向登录界面
return Promise.reject(response);}// 无response，有可能是接口跨域了
if(!response){// key避免多次提示
message.error({content:'服务错误',key:'response-error'});return Promise.reject('服务错误');}if(response.status===403){message.error('没有当前功能权限，请联系管理员');return Promise.reject(response);}if(response.status===400){message.error(msgInfo);return Promise.reject(response);}if(response.status===504){message.error('服务器超时，请稍后再试');return Promise.reject(response);}return Promise.reject(response);}catch(e){console.log('error',e);}return error;});export async function request(path,params,options){var _axiosResult$data,_axiosResult$data2;let platform=arguments.length>3&&arguments[3]!==undefined?arguments[3]:'api';const url=getUrl(path,platform);const opts=_objectSpread(_objectSpread({},options),{},{url,data:params});// get, delete请求参数处理
if(options.method.toLowerCase()==='get'||options.method.toLowerCase()==='delete'){const query=params?"?".concat(qs.stringify(params)):'';opts.url="".concat(url).concat(query);}const axiosResult=await axios.request(opts);return(axiosResult===null||axiosResult===void 0?void 0:(_axiosResult$data=axiosResult.data)===null||_axiosResult$data===void 0?void 0:_axiosResult$data.data)||(axiosResult===null||axiosResult===void 0?void 0:(_axiosResult$data2=axiosResult.data)===null||_axiosResult$data2===void 0?void 0:_axiosResult$data2.result)||(axiosResult===null||axiosResult===void 0?void 0:axiosResult.data);}export function get(path,params,config){let platform=arguments.length>3&&arguments[3]!==undefined?arguments[3]:'api';return request(path,params,_objectSpread({method:'GET'},config),platform);}export function post(path,params,config){let platform=arguments.length>3&&arguments[3]!==undefined?arguments[3]:'api';return request(path,params,_objectSpread({method:'POST'},config),platform);}export function patch(path,params,config){let platform=arguments.length>3&&arguments[3]!==undefined?arguments[3]:'api';return request(path,params,_objectSpread({method:'PATCH'},config),platform);}export function put(path,params,config){let platform=arguments.length>3&&arguments[3]!==undefined?arguments[3]:'api';return request(path,params,_objectSpread({method:'PUT'},config),platform);}export function remove(path,params,config){let platform=arguments.length>3&&arguments[3]!==undefined?arguments[3]:'api';return request(path,params,_objectSpread({method:'DELETE'},config),platform);}export default request;