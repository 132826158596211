// center pre和production 地址相同
const AdminUrlMap={development:'http://192.168.13.140:8182/enterprise/#/login',qa:'http://192.168.13.140:8182/enterprise/#/login',// 测试
pre:window.adminUrl||"http://center.lubansoft.com/#/login",// 预发布环境
production:window.adminUrl||"http://center.lubansoft.com/#/login"// 线上
};export function getCenterAdminUrl(){return AdminUrlMap[process.env.NODE_STAGE||'development'];}// 咨询作业地址
// center pre和production 地址相同
const CmsUrlMap={development:'http://192.168.13.140:3001/#/login',qa:'http://192.168.13.140:3001/#/login',// 测试
pre:window.adminUrl||"https://ces.lubansoft.com/",// 预发布环境
production:window.adminUrl||"https://ces.lubansoft.com/"// 线上
};// 获取资讯作业地址
export function getCmsUrl(){return CmsUrlMap[process.env.NODE_STAGE||'development'];}