import{FieldTypeEnum}from'@/config/enum';import dayjs from'dayjs';import{FormatMap}from'./enum';import{traverseTree}from'@/utils/tree';// 是否是有效的搜索值
export const isValidSearchValue=value=>{if(value===null||value===undefined){return false;}if(typeof value==='string'){const trimValue=value.trim();if(trimValue==='')return false;if(trimValue==='all')return false;// 如果是 all，也认为是无效的值。不会向后端发送参数
return true;}if(Array.isArray(value)&&value.length===0){return false;}return true;};// FIXME: 性能有问题，需要优化。
const getRegionName=(options,code)=>{let res='';traverseTree(options,node=>{if(node.code===code){res=node.name;}});return res||'-';};// 获取需要展示的搜索标签值，如果是字符串数组，用逗号分隔。日期类型展示为 YYYY-MM-DD，日期范围数组展示为 YYYY-MM-DD 至 YYYY-MM-DD
export const getTagValue=(fieldName,fieldValue,config)=>{const fieldItem=config.find(item=>item.name===fieldName);if(!isValidSearchValue(fieldValue)){return'';}if(Array.isArray(fieldValue)){if(fieldValue.length===0){return'';}// 日期范围
if(fieldItem.componentType===FieldTypeEnum.DATETIME_RANGE){const format=FormatMap[fieldItem.picker||'date'];const start=dayjs(Number(fieldValue[0])).format(format)||'';const end=dayjs(Number(fieldValue[1])).format(format)||'';return"".concat(start," \u81F3 ").concat(end);}// 级联
if(fieldItem.componentType===FieldTypeEnum.CASCADER){// 地点
if(fieldName==='location'){const names=fieldValue.map(code=>getRegionName(fieldItem.options,code)).join(',');return names;}}return fieldValue.join(',');}if(typeof fieldValue==='object'){return"[Object] ".concat(JSON.stringify(fieldValue),"]");}if(typeof fieldValue==='string'||typeof fieldValue==='number'){if(fieldItem.componentType===FieldTypeEnum.DATETIME){return dayjs(Number(fieldValue)).format('YYYY-MM-DD');}if(fieldItem.componentType===FieldTypeEnum.SELECT){var _fieldItem$options$fi;return((_fieldItem$options$fi=fieldItem.options.find(item=>item.value===fieldValue))===null||_fieldItem$options$fi===void 0?void 0:_fieldItem$options$fi.label)||'';}}return fieldValue;};// 获取当前schema 配置
export const getSchemaItemByKey=(config,key)=>config.find(item=>item.key===key);// 获取config所有默认值对象
export const getDefaultValues=config=>{const obj={};config.forEach(item=>{if(item.defaultValue){obj[item.key]=item.defaultValue;}});return obj;};// 获取config所有rest对象
export const getRestValues=config=>{const obj={};config.forEach(item=>{if(item.rest){obj[item.key]=item.resetValue;}});return obj;};// 过滤对象中的空值
export const filterEmptyValue=obj=>{const result={};Object.keys(obj).forEach(key=>{if(isValidSearchValue(obj[key])){result[key]=obj[key];}});return result;};