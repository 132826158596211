import _objectSpread from"/Users/mac/code/luban/test/cost-cloud-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";import{DefaultSystemKey}from'@/config';import{create}from'zustand';import{menuKeysMap}from'./menu';import{SystemEnum}from'@/config/enum';const initialState={menuKeysMap,// 用户相关信息
userInfo:null,// 菜单收缩
collapsed:false,authCodes:[],currentEnterprise:null,authorized:false,enterprisesList:[],preRoute:'',// 登录相关信息
token:'',// 鉴权token
tenantId:'',// 租户id，U开头代表个人
curHeaderMenuKey:DefaultSystemKey,lastLoginTenantId:null,unitConvertMap:null,unitAliasMap:null,systemKey:SystemEnum.SYSTEM,systemMenuOpen:false,setting:{PrecisionSetting:{}},basicConfigInfo:{}};const useGlobalStore=create()((set,get)=>_objectSpread(_objectSpread({},initialState),{},{resetGlobalStore:()=>{set(initialState);},setGlobalInfo:state=>set(_objectSpread(_objectSpread({},get()),state)),// 设置用户信息
setUserInfo:userInfo=>set({userInfo:_objectSpread({},userInfo)}),setCollapsed:status=>set({collapsed:status}),setAuthCodes:authCodes=>set({authCodes:[...authCodes]}),getAuthCodes:()=>get().authCodes,setCurrentEnterprise:currentEnterprise=>set({currentEnterprise}),setAuthorized:authorized=>set({authorized}),setCurHeaderMenuKey:curHeaderMenuKey=>set({curHeaderMenuKey}),getCurHeaderMenuKey:()=>get().curHeaderMenuKey,getMenuKeysMap:()=>menuKeysMap,// 获取单位换算系数
getConverFactor(originUnit,targetUnit){var _get$unitAliasMap,_get$unitAliasMap2;// 获取标准单位
let originStandardUnit=(_get$unitAliasMap=get().unitAliasMap)===null||_get$unitAliasMap===void 0?void 0:_get$unitAliasMap[originUnit];if(!originStandardUnit)return'';let targetStandardUnit=(_get$unitAliasMap2=get().unitAliasMap)===null||_get$unitAliasMap2===void 0?void 0:_get$unitAliasMap2[targetUnit];if(!targetStandardUnit)return'';// 相等返回1
if(String(originStandardUnit).trim()===String(targetStandardUnit).trim())return'1';// 获取系数
const targetFactor=get().unitConvertMap["".concat(originStandardUnit,"_").concat(targetStandardUnit)];return targetFactor||'';}}));export default useGlobalStore;