// antd5 主题定制
// 见： https://ant.design/docs/react/customize-theme-cn/
// NOTE: 对应css变量，在src/styles/variables.less中定义
// echart 颜色配置
export const color=['#589FF4','#25DEEA'];export const customTheme={colorPrimary:'#288DFF',// colorError: '#D40000',
colorWarning:'#FAAB0C',colorText:'#2E3033',colorBorder:'#E1E2E5',// 背景颜色，菜单和头部组件等
colorBgContainer:'#ffffff',// 基础组件的圆角大小，例如按钮、输入框、卡片等
borderRadius:2,controlHeight:36,controlHeightLG:48,colorTextHeading:'#66686C',fontWeightStrong:400,colorBgLayout:'#f7f9fb'// controlHeightSM: 24,
// controlHeightXS: 16,
// controlHeightLG: 48,
// antLessVars:antLessVars.tb
};// 模块之前的margin
export const margin=16;// 框架 header 高度
export const G_HeaderHeight=64;export const BreadHeight=38;// 面包屑高度 + 底部padding
// export const BreadHeight = 38 + 10 // 面包屑高度 + 底部padding
export const BreadOpen=true;// 是否开启面包屑