import _objectSpread from"/Users/mac/code/luban/test/cost-cloud-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";import dayjs from'dayjs';import{create}from'zustand';// define the initial state
const initialState={projectSearchKeyword:'',allProjectFields:[],searchProjectFields:[],authCodes:[],authed:false,allYetaiList:[],allLocationList:[],locationOptions:[],yetaiOptions:[],curSelectProjectItem:null,projectArchiveOpen:false,tableParams:{tenantId:'',searchKey:'',templateId:'',ascription:'',isArchived:''},createProjectOpen:false,columnsSettingOpen:false,projectDetailOpen:false,orgTreeOpen:false,orgSearchValue:'',orgTreeData:[],orgTreeSourceData:[],templateOptions:[],newProjectTemplateOptions:[],tableShowData:[],tableSourceData:[],rowSelectKeys:[],columnSettingArray:[],columnSettingSelectKeys:[],// 默认选中字段:项目名称。
allDictData:[],spaceData:{space:'',// 所有空间
used:''// 已用空间
},orgSelectKeys:[],orgSelectNode:null,collapsed:false,globalSearchOpen:false,exportOpen:false,provinceName:'',provinceCode:'',monthValue:[dayjs().startOf('year'),dayjs().endOf('year')],chartYetaiList:[],chartData:{htResults:[],// 合同
fwResults:[],// 服务
yhtResults:[],// 月合同
dqResults:[],// 地区
ytResults:[],// 业态
mapResults:[]// 项目分布
},searchFormData:{},chartAmountData:[],chartMapDescData:[],searchClickType:''};const useStore=create()((set,get)=>_objectSpread(_objectSpread({},initialState),{},{setAuthCodes:v=>set({authCodes:v}),setOrgTreeDatasetOrgTreeData:v=>set({orgTreeData:v}),setSearchValue:v=>set({orgSearchValue:v}),resetStore:()=>set(initialState),getMonthValue:()=>{const{monthValue}=get();const startMills=monthValue?dayjs(monthValue[0]).startOf('month').format('YYYY-MM-DD'):undefined;const endMills=monthValue?dayjs(monthValue[1]).endOf('month').format('YYYY-MM-DD'):undefined;return[startMills,endMills];},getEchartQueryParams:()=>{const{orgSelectKeys,provinceCode}=get();const[startMills,endMills]=get().getMonthValue();return{startDate:startMills,endDate:endMills,orgId:orgSelectKeys===null||orgSelectKeys===void 0?void 0:orgSelectKeys.at(0),province:provinceCode};}}));export default useStore;