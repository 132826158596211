import{formatDate}from"@/utils/date";import{useEffect,useState,useRef}from"react";// 自定义 Hook，用于带有空闲检测的轮询操作
function usePollingWithIdleCheck(_ref){let{checkLockFn,idleTimeout=3000,interval=60000}=_ref;/**
     * @param {function} checkLockFn - 轮询调用的锁检测函数，定义了具体的轮询逻辑。
     * @param {number} idleTimeout - 用户空闲时间阈值，默认 3000 毫秒（3 秒）。如果用户在这段时间内无操作，将暂停轮询。
     * @param {number} interval - 轮询的时间间隔，默认 60000 毫秒（60 秒）。每次轮询之间的时间间隔。
     */const isPollingRef=useRef(true);// 使用 ref 代替 isPolling state
const[isPolling,setIsPolling]=useState(true);// 用来更新组件状态值，用来更新组件
const idleTimeRef=useRef(0);// 用于记录当前的空闲时间
const pollingIntervalRef=useRef(null);// 保存轮询的定时器引用
const idleIntervalRef=useRef(null);// 保存空闲时间检测的定时器引用
// console.log("idleTimeout", idleTimeout)
// 开始轮询的函数
const startPolling=()=>{// 如果当前没有轮询定时器，开始轮询
if(!pollingIntervalRef.current){console.log("startPolling",pollingIntervalRef.current,interval,formatDate(new Date(),'YYYY-MM-DD HH:mm:ss'));pollingIntervalRef.current=setInterval(checkLockFn,interval);isPollingRef.current=true;// 更新 ref 状态
setIsPolling(true);// 更新状态
}};// 停止轮询的函数
const stopPolling=()=>{console.log("stopPolling");// 如果存在轮询定时器，清除定时器并重置引用
if(pollingIntervalRef.current){clearInterval(pollingIntervalRef.current);pollingIntervalRef.current=null;}isPollingRef.current=false;// 确保每次都更新 ref 状态
setIsPolling(false);// 更新状态
};useEffect(()=>{// 重置空闲时间的函数
const resetIdleTime=()=>{idleTimeRef.current=0;// 将空闲时间重置为 0
// console.log("resetIdleTime", isPollingRef.current)
if(!isPollingRef.current){checkLockFn();// 立即触发轮询
startPolling();// 重新开始轮询
}};// 每秒执行一次的空闲检测函数
const timerIncrement=()=>{idleTimeRef.current+=1000;// 每次调用时增加 1000 毫秒（1 秒）
// 如果空闲时间超过 idleTimeout，停止轮询
if(idleTimeRef.current>=idleTimeout){stopPolling();// 暂停轮询
idleTimeRef.current=0;// 重置空闲时间计数
}};// 如果当前需要轮询，启动轮询
// if (isPolling) startPolling();
startPolling();// 每秒执行一次空闲检测，检测用户是否达到空闲状态
idleIntervalRef.current=setInterval(timerIncrement,1000);// 监听用户交互事件重置空闲时间
const resetEvents=["mousemove","mousedown","keydown","scroll"];resetEvents.forEach(event=>window.addEventListener(event,resetIdleTime));// 清理函数，组件卸载时移除事件监听和清除定时器
return()=>{stopPolling();// 停止轮询
clearInterval(idleIntervalRef.current);// 清除空闲检测定时器
resetEvents.forEach(event=>window.removeEventListener(event,resetIdleTime));};},[interval,idleTimeout]);return{isPolling};// 返回轮询状态
}export default usePollingWithIdleCheck;