import _objectWithoutProperties from"/Users/mac/code/luban/test/cost-cloud-web/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";import _objectSpread from"/Users/mac/code/luban/test/cost-cloud-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";const _excluded=["children"],_excluded2=["children"],_excluded3=["children"];import{produce}from'immer';import{cloneDeep}from'lodash-es';// 树相关工具函数
// 遍历树节点，深度优先遍历
export function traverseTree(tree,callback){for(const node of tree){// 调用回调函数，将当前节点传递给回调函数
callback(node);if(node!==null&&node!==void 0&&node.children){// 如果当前节点有子节点，则递归遍历子节点
traverseTree(node.children,callback);}}}// 给tree添加index，格式为1.1 1.1.1
// export const setTreeIndex = (arr, parentIndex = '') => {
//   arr.forEach((item, index) => {
//     const currentIndex = parentIndex ? `${parentIndex}.${index + 1}` : `${index + 1}`
//     item.index = currentIndex
//     if (item.children?.length > 0) {
//       setTreeIndex(item.children, currentIndex)
//     }
//   })
//   return arr
// }
// 支持生成深度字段
export const setTreeIndex=arr=>{let parentNum=0;const run=function(arr){let parentIndex=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'';let depth=arguments.length>2&&arguments[2]!==undefined?arguments[2]:1;if(!arr||arr.length===0)return arr;arr.forEach((item,index)=>{var _item$children;const currentIndex=parentIndex?"".concat(parentIndex,".").concat(index+1):"".concat(index+1);item._index=currentIndex;item._num=++parentNum;item.depth=depth;item.isLast=index===arr.length-1;if(((_item$children=item.children)===null||_item$children===void 0?void 0:_item$children.length)>0){run(item.children,currentIndex,depth+1);}});return arr;};return run(arr,'',0);};// 数组转换成树，不改变原数组
export function arrayToTree(arr,_ref){let{idField,pidField,keyField='id',titleField='name',valueField='id',needChildField=true,needIndex=true// 是否需要索引
}=_ref;const map={};const tree=[];// 所有节点的key值
const expandSet=new Set();// 构建节点映射表
arr.forEach(node=>{map[node[idField]]=_objectSpread(_objectSpread({},node),{},{id:node[idField],pid:node[pidField],key:node[keyField],title:node[titleField],value:node[valueField],children:[]});expandSet.add(node[keyField]);});// 构建树形结构
arr.forEach(node=>{const parent=map[node[pidField]];if(parent){parent.children.push(map[node[idField]]);}else{tree.push(map[node[idField]]);}});// 标记叶子节点。antd 树形组件会用到
Object.values(map).forEach(node=>{var _node$children;if((node===null||node===void 0?void 0:(_node$children=node.children)===null||_node$children===void 0?void 0:_node$children.length)===0){node.isLeaf=true;if(!needChildField){// node.children = null
delete node.children;}}else{node.isLeaf=false;}});let newTree=tree;// 是否需要索引
if(needIndex){newTree=setTreeIndex(tree);}return{map,tree:newTree,expandKey:Array.from(expandSet)};}// 根据唯一标识符获取树节点
export function getTreeNodeByField(nodes,field,value){// 遍历树形数据
for(let i=0;i<nodes.length;i++){const node=nodes[i];// 检查当前节点是否是目标节点
if(node[field]===value){return node;}// 如果当前节点有子节点，则递归调用 getNodeById 函数继续在子节点中查找
if(node.children&&node.children.length>0){const foundNode=getTreeNodeByField(node.children,field,value);if(foundNode){return foundNode;}}}return null;}// 根据字段和值查找节点下面的所有子节点数组
export function findNodeChildren(_ref2){let{tree,field,value,needCurrentNode=false}=_ref2;// 用于存储匹配的子节点数组
let result=[];// 递归遍历树的函数
function traverse(node){// 将当前节点的子节点添加到结果数组中
if(node.children){const childrenWithoutChildrenField=node.children.map(_ref3=>{let{children}=_ref3,rest=_objectWithoutProperties(_ref3,_excluded);return rest;});result=result.concat(childrenWithoutChildrenField);}// 检查当前节点是否有子节点
if(node.children&&node.children.length>0){// 递归遍历子节点
for(const child of node.children){traverse(child);}}}const findNode=getTreeNodeByField(tree,field,value);if(findNode){// 遍历树收集所有子节点
traverse(findNode);}if(needCurrentNode){// 返回结果数组，包含当前节点
return result.concat(findNode||[]);}return result;}// 添加子节点到指定属性的节点
export function appendChildNodeByField(tree,field,fieldValue,childNodes){return produce(tree,draft=>{const parentNode=getTreeNodeByField(draft,field,fieldValue);if(parentNode){if(!parentNode.children){parentNode.children=[];}childNodes.forEach(i=>parentNode.children.push(i));}});}// 获取树结构所有展开的节点
export function getTreeAllExpandedNodes(tree){const expandedNodes=[];function traverse(nodes){for(const node of nodes){if(node.children&&node.children.length>0){expandedNodes.push(node);traverse(node.children);}}}traverse(tree);return expandedNodes;}export function markLastChild(tree){if(!tree||tree.length===0)return;tree.forEach((node,index)=>{if(node.children&&node.children.length>0){markLastChild(node.children);}node.isLast=index===tree.length-1;});}// 添加索引和最末子节点标记
export function addTreeIndexTag(tree){let parentIndex=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'';if(!tree||tree.length===0)return tree;tree.forEach((node,index)=>{const currentIndex=parentIndex?"".concat(parentIndex,".").concat(index+1):"".concat(index+1);node.index=currentIndex;node.isLast=index===tree.length-1;if(node.children){addTreeIndexTag(node.children,currentIndex);}});return tree;}// 获取树层级
export function getTreeLevel(tree){let level=0;function traverse(nodes,currentLevel){for(const node of nodes){if(node.children&&node.children.length>0){traverse(node.children,currentLevel+1);}else{level=Math.max(level,currentLevel);}}}traverse(tree,1);return level;}// 获取树所有节点的某个字段集合
export function getTreeFields(tree,fieldName){const res=[];traverseTree(tree,node=>{res.push(node[fieldName]);});return res;}// 获取树所有节点
export function getAllTreeNodes(tree){const res=[];traverseTree(tree,node=>{const{children}=node,pureNode=_objectWithoutProperties(node,_excluded2);res.push(pureNode);});return res;}// 根据数组中指定的id生成树结构
export function buildTreeById(arr,_ref4){let{idValue='',idField='id',pidField='parentId'}=_ref4;const res=[];for(const item of arr){if(item[pidField]===idValue){// 找到当前id的子元素
// 插入子元素，每个子元素的children通过回调生成
res.push(_objectSpread(_objectSpread({},item),{},{children:buildTreeById(arr,item[idField])}));}}return res;}// 有条件的过滤树结构数组，会保存父级节点
export const filterTree=(treeData,condition)=>{const cloneData=cloneDeep(treeData);function fn(tree,conditionFn){return tree.filter(node=>{const currentNodeMatches=conditionFn(node);if(currentNodeMatches){return true;}if(node.children){const childMatches=fn(node.children,conditionFn);node.children=childMatches;return childMatches.length>0;// Only keep nodes with matching children
}return false;});}// return produce(treeData, draft => fn(draft,condition)) as unknown as any[]
return fn(cloneData,condition);};// 根据条件获取树节点
export function getTreeNode(tree,condition){for(const node of tree){if(condition(node)){return node;}if(node.children){const childNode=getTreeNode(node.children,condition);if(childNode){return childNode;}}}return null;}// 获取多个符合条件的树节点
export function getTreeNodes(tree,condition){let res=[];for(const node of tree){if(condition(node)){res.push(node);}if(node.children){res=res.concat(getTreeNodes(node.children,condition));}}return res;}// 删除一个树节点,
export function deleteTreeNodeByKey(tree,key){const deleteNode=nodes=>{return nodes.filter(node=>{if(node.key===key){return false;// 返回 false 以从数组中删除此节点
}if(node.children){node.children=deleteNode(node.children);// 递归处理子节点
}return true;// 保留其他节点
});};return deleteNode(tree);}// 删除一个树节点
export function deleteTreeNodeByKeyWhihImmer(tree,key){return produce(tree,draftState=>{const deleteNode=nodes=>{return nodes.filter(node=>{if(node.key===key){return false;// 返回 false 以从数组中删除此节点
}if(node.children){node.children=deleteNode(node.children);// 递归处理子节点
}return true;// 保留其他节点
});};deleteNode(draftState);});}// 删除一个树节点
export function deleteTreeNodeByKeysWhihImmer(tree,keys){return produce(tree,draftState=>{const deleteNode=nodes=>{return nodes.filter(node=>{if(keys.includes(node.key)){return false;// 返回 false 以从数组中删除此节点
}if(node.children){node.children=deleteNode(node.children);// 递归处理子节点
}return true;// 保留其他节点
});};deleteNode(draftState);});}// 删除一个树所有子节点，只保留根
export function deleteTreeChildNodeByKeyWhihImmer(tree,key){return produce(tree,draftState=>{const deleteNode=nodes=>{return nodes.filter(node=>{if(node.key===key){node.children=[];}return true;});};deleteNode(draftState);});}// 删除一个树节点
export function deleteTreeNodeByConditin(tree,condition){let options=arguments.length>2&&arguments[2]!==undefined?arguments[2]:{needChildren:true};const{needChildren}=options;// 遍历过程中记录id和树节点的映射关系，方便快速查找
const treeIndexMap={};const copyTree=cloneDeep(tree);const deleteNode=nodes=>{const filterData=nodes.filter(node=>{if(condition(node)){return false;// 返回 false 以从数组中删除此节点
}if(node.children){node.children=deleteNode(node.children);// 递归处理子节点
// 记录id和树节点的映射关系
treeIndexMap[node.key]=node;// 如果子节点都被删除了，且不需要子节点，则标记为叶子节点
if(!needChildren&&node.children.length===0){node.isLeaf=true;}}return true;// 保留其他节点
});return filterData;};deleteNode(copyTree);return{tree:copyTree,map:treeIndexMap};}// 找到树节点所有父元素
export const findNodeParents=(treeData,nodeId)=>{let result=[];const getRestNode=node=>{const{children}=node,rest=_objectWithoutProperties(node,_excluded3);return rest;};const find=(tree,id)=>{for(let i=0;i<tree.length;i++){const node=tree[i];if(node.id===id){result.push(getRestNode(node));return true;}if(node.children&&node.children.length>0){if(find(node.children,id)){result.push(node);return true;}}}return false;};find(treeData,nodeId);return result;};// 找到树节点所有父元素的名称
export const findNodeNameParents=(treeData,nodeId)=>{let result=[];const find=(tree,id)=>{for(let i=0;i<tree.length;i++){const node=tree[i];if(node.id===id){result.push(node.name);return true;}if(node.children&&node.children.length>0){if(find(node.children,id)){result.push(node.name);return true;}}}return false;};find(treeData,nodeId);return result;};// 判断是否是父节点的第一个子节点
// export function isFirstChild(tree, target, fieldNames?: {
//   id: 'id',
//   parentId: 'parentId'
// }) {
//   if (!target[fieldNames['parentId']]) return true;
//   const parent = getTreeNode(tree, node => node[fieldNames['id']] === target[fieldNames['parentId']]);
//   if (parent && parent.children.length > 0) {
//     return parent.children[0][fieldNames['id']] === target[fieldNames['parentId']];
//   }
//   return false;
// }
export function isFirstChild(tree,target){let fieldNames=arguments.length>2&&arguments[2]!==undefined?arguments[2]:{id:'id',parentId:'parentId'};if(!target[fieldNames.parentId])return true;const parent=getTreeNode(tree,node=>node[fieldNames.id]===target[fieldNames.parentId]);if(parent&&parent.children.length>0){return parent.children[0][fieldNames.id]===target[fieldNames.id];}return false;}// 判断是否为父节点下的最后一个子节点
export function isLastChild(tree,target){let fieldNames=arguments.length>2&&arguments[2]!==undefined?arguments[2]:{id:'id',parentId:'parentId'};if(!target[fieldNames.parentId])return true;const parent=getTreeNode(tree,node=>node[fieldNames.id]===target[fieldNames.parentId]);if(parent&&parent.children.length>0){return parent.children[parent.children.length-1][fieldNames.id]===target[fieldNames.id];}return false;}// export function isLastChild(tree, target) {
//   if (!target.parentId) return true;
//   const parent = getTreeNode(tree, node => node.id === target.parentId);
//   if (parent && parent.children.length > 0) {
//     return parent.children[parent.children.length - 1].id === target.id;
//   }
//   return false;
// }
export function findSubtreeById(tree,id){for(let node of tree){if(node.id===id){return[node];// 找到节点，返回当前节点及其子节点
}if(node.children){const result=findSubtreeById(node.children,id);console.log('result',result.length);if(result.length){return[_objectSpread(_objectSpread({},node),{},{children:result})];// 返回当前节点及其子节点
}}}return null;// 没有找到节点
}